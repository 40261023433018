<template>
	<div class="add test-dark">
		<!-- 购买弹窗 -->
		<div style="color: green;width: 1000px;height: 400px;display: flex;flex-direction: column;align-items: center;">
			<div style="display: flex;">
				<div v-for="(item,index) in list" @click="changeArea(item,index)" :class="{'active': current == index}"
					style="width: 80px;height: 40px;margin-right: 40px;border: 1px solid green;">{{item.name}}</div>
				
				<!-- <div style="width: 80px;height: 40px;border: 1px solid green;">222</div> -->
			</div>
			<div style="display: flex;">
				<div v-for="(items,indexs) in list[current].list1" @click="changeArea1(items,indexs)"
					:class="{'active': current1 == indexs}"
					style="width: 80px;height: 40px;margin-right: 40px;border: 1px solid green;">{{items.name}}
				</div>
			</div>
			<div style="display: flex;">
				<div v-for="(item,index) in list2" @click="changeArea2(item,index)" :class="{'active': current2 == index}"
					style="width: 80px;height: 40px;margin-right: 40px;border: 1px solid green;">{{item.name}}</div>
			</div>
			<div>{{price}}</div>
		</div>
		<p class="addp">{{ $t("hello") }}
		</p>
		<!--    <svg class="icon menuIcon" aria-hidden="true">-->
		<!--      <use href="iconfont icon-7key-musicxiangshang"></use>-->
		<!--    </svg>-->
		<svg class="svg" aria-hidden="true">
			<use href="#icon-7key-musicxiangshang" />
		</svg>
		<i class="7keymusic icon-7key-musicxiangshang"></i>

		<Icon name="icon-7key-musictongzhi" theme-color="primaryColor" color="" width="30" height="30" />


		<el-row>
			<el-button @click="changeLang('cn')">切换语言中文</el-button>
			<el-button @click="changeLang('ko')">切换语言韩语</el-button>
			<el-button @click="changeLang('en')">切换语言英语</el-button>
			<el-button @click="changTheme('default')">默认主题</el-button>
			<el-button @click="changTheme('dark')">暗黑主题</el-button>
		</el-row>
		<div class="block">
			<el-date-picker v-model="value1" type="date" placeholder="选择日期">
			</el-date-picker>
		</div>
		<!--  轮播图相关  -->
		<swiper class="swiper" :options="swiperOption" ref="mySwiper">
			<swiper-slide>Slide 1</swiper-slide>
			<swiper-slide>Slide 2</swiper-slide>
			<swiper-slide>Slide 3</swiper-slide>
			<swiper-slide>Slide 4</swiper-slide>
			<swiper-slide>Slide 5</swiper-slide>
			<swiper-slide>Slide 6</swiper-slide>
			<swiper-slide>Slide 7</swiper-slide>
			<swiper-slide>Slide 8</swiper-slide>
			<swiper-slide>Slide 9</swiper-slide>
			<swiper-slide>Slide 10</swiper-slide>
			<div class="swiper-pagination" slot="pagination"></div>
			<div class="swiper-button-prev" slot="button-prev"></div>
			<div class="swiper-button-next" slot="button-next"></div>
		</swiper>
		<button @click="prev">上</button>
		<button @click="next">下</button>


		<!--    <el-upload-->
		<!--        class="avatar-uploader"-->
		<!--        :action="uploadUrl"-->
		<!--        :headers="uploadHeaders"-->
		<!--        :show-file-list="false"-->
		<!--        :on-success="handleAvatarSuccess"-->
		<!--        :before-upload="beforeAvatarUpload">-->
		<!--      <img v-if="imageUrl" :src="imageUrl" class="avatar">-->
		<!--      <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
		<!--    </el-upload>-->

		<el-upload :action="uploadUrl" list-type="picture-card" name="image" :before-upload="beforeAvatarUpload"
			:on-success="handleAvatarSuccess" :show-file-list="false" ref="cover">
			<img v-if="imageUrl" :src="imageUrl" class="avatar">
			<i v-else slot="default" class="el-icon-plus"></i>
		</el-upload>
		<!--    <i class="iconfont icon-bank-JSB"></i>-->
		<Icon name="#icon-yinhanglogo-72" theme-color="" color="" width="30" height="30" />
	</div>
</template>

<script>
	import {
		setTheme
	} from "@/assets/theme";
	import {
		getFirstPageFootImage
	} from "@/api/Home";
	import {
		swiper,
		swiperSlide
	} from 'vue-awesome-swiper'

	export default {
		props: {},
		data() {
			return {
				price: '0',
				list: [{
						name: '全球',
						// price: 100,
						list1: [{
								name: '影音同步',
								price: 37000
							},
							{
								name: '无线运营',
								price: 32560
							},
							{
								name: '灌录权',
								price: 29600
							}
						]
					},
					{
						name: '大陆',
						list1: [{
								name: '影音同步1',
								price: 33300
							},
							{
								name: '无线运营1',
								price: 29304
							},
							{
								name: '灌录权1',
								price: 26640
							}
						]
						// price: 200
					}
				],
				current: 0,
				// list1: [{
				// 		name: '影音',
				// 		price: 300
				// 	},
				// 	{
				// 		name: '影院',
				// 		price: 400
				// 	}
				// ],
				current1: 0,
				list2: [{
						name: '三年',
						mul: 0.8
					},
					{
						name: '五年',
						mul: 0.9
					},
					{
						name: '八年',
						mul: 1
					}
				],
				current2: 0,

				value1: "",
				currentSwiper: null,
				slideList: [{
						name: "aaa"
					},
					{
						name: "bbb"
					},
					{
						name: "ccc"
					},
				],
				currentIndex: 1,
				swiperOption: { //同swiper
					slidesPerView: 1,
					spaceBetween: 30,
					loop: true,
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					}
				},
				imageUrl: '',
				uploadUrl: process.env.VUE_APP_UPLOAD_IMAGE_URL,
				uploadHeaders: {
					'Content-Type': 'multipart/form-data'
				}
			};
		},
		components: {
			swiper,
			swiperSlide
		},
		computed: {},
		created() {},
		mounted() {
			getFirstPageFootImage()
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
		},
		watch: {},
		methods: {
			changeArea(item,index) {
				this.current = index
				console.log(this.current,this.current1,this.current2)
				console.log(item.list1[this.current1].price,this.list2[this.current2].mul,'1111')
				this.price = item.list1[this.current1].price * this.list2[this.current2].mul
			},
			changeArea1(item,index) {
				this.current1 = index
				console.log(this.current,this.current1,this.current2)
				console.log(item.price,this.list2[this.current2].mul,'2222')
				this.price = item.price * this.list2[this.current2].mul
			},
			changeArea2(item,index) {
				this.current2 = index
				console.log(this.current,this.current1,this.current2)
				console.log(this.price,item.mul,'3333')
				this.price = this.list[this.current].list1[this.current1].price * item.mul
			},

			changeLang(lang) {
				this.$i18n.locale = lang
				localStorage.setItem('lang', lang)
			},
			changTheme(name) {
				setTheme(name)
			},
			//鼠标移入暂停自动播放
			stopAutoPlay() {
				this.currentSwiper.autoplay.stop()
			},
			//鼠标移出开始自动播放
			startAutoPlay() {
				this.currentSwiper.autoplay.start()
			},
			prev() {
				this.$refs.mySwiper.swiper.slidePrev();
			},
			next() {
				this.$refs.mySwiper.swiper.slideNext()
			},
			handleAvatarSuccess(res, file) {
				console.log(file)
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				// const isLt2M = file.size / 1024 / 1024 < 2;
				//
				// if (!isJPG) {
				//   this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				// if (!isLt2M) {
				//   this.$message.error('上传头像图片大小不能超过 2MB!');
				// }
				// return isJPG && isLt2M;
				return true
			}
		},
	};
</script>

<style scoped lang="less">
	.active {
		background-color: #409EFF;
	}

	.svg {
		fill: @primaryColor;
	}

	.add {
		background: @baseBackgroundColor;

		.addp {
			color: red;
		}

		button {
			color: khaki;
		}

		.swiper {
			height: 300px;
			width: 100%;
		}

		.swiper-slide {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			font-weight: bold;
			font-size: 16px;
			background-color: #ffffff;
		}

		.avatar-uploader .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}

		.avatar-uploader .el-upload:hover {
			border-color: #409EFF;
		}

		.avatar-uploader-icon {
			font-size: 28px;
			color: #8c939d;
			width: 178px;
			height: 178px;
			line-height: 178px;
			text-align: center;
		}

		.avatar {
			width: 178px;
			height: 178px;
			display: block;
		}
	}
</style>
